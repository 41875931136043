.box {
  display: flex;
}

.box-all {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.box-wrap {
  flex-wrap: wrap;
}

.box-col {
  flex-direction: column;
}

.case-auto {
  flex: 1;
}

.case-20 {
  flex: 20% 0 0;
}

.case-25 {
  flex: 25% 0 0;
}

.case-30 {
  flex: 30% 0 0;
}

.case-40 {
  flex: 40% 0 0;
}

.case-5 {
  flex: 5% 0 0;
}

.case-min {
  flex: 0 0;
}

@media (max-width: 800px) {

  .box-lm-col {
    flex-direction: column;
  }

  .case-lm-min {
    flex: 0 0;
  }

}
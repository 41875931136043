.hovertext {
  position: relative;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 150px;
  background-color: rgba(0, 0, 0, 0.85);
  text-align: center;
  padding: 8px;
  transition: opacity 0.3s ease-in-out;
  border-radius: 8px;

  color: #fff !important;
  font-weight: normal !important;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}
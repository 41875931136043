$ohBlueDark: #121A6D;
$ohBlueLight: #2c369a;

$ohBlueMat: #152342;

$ohBlackOp : rgba(31, 41, 55, 0.75);

$ohBlack : rgb(31, 41, 55);
$ohBlackLight : rgb(42, 50, 64);
$ohBlackSmallLight : rgb(38, 46, 58);
$ohBlackSombre : rgb(35, 44, 56);
$ohBlackClicked : rgb(15, 19, 29);
$ohBlackClickedHover : rgb(23, 28, 45);

$ohGrey : #D6D6D6;
$ohGreen : #00af73;
$ohGreenLight : #00c48a;
$ohRed : #ff4747;
$ohRedLight : #ff5555;
$ohBlue : #0060af;
$ohOrange : #ee7a00;
$ohYellow : #ffd814;

$color1 : rgb(183, 198, 255);
$color2 : rgb(133, 218, 255);
$color3 : rgb(255, 185, 245);
.buy-box {
  .buy-card {
    padding: 6px;
  }
}

.total {
    margin-top: 12px
}

.pay-btn, .return-btn {
  i {
    margin-right: 4px;
  }

  font-weight: bold;
  letter-spacing: 2px;

  padding: 12px;
  margin: 12px 7px;
}

.return-btn {
  font-weight: normal;
  letter-spacing: 0;

  padding: 8px;
}

.popup {

  h5 {
    font-weight: normal;
  }

  h4 {
    margin: 12px 0;
  }

  .content {
    i {
      margin-right: 6px;
    }

    h4 {
      text-align: center;
    }
  }
}
.credit-case {
  background: $ohBlackLight;
  transition-duration : 0.3s;
  padding: 10px;
  border-radius: 8px;
  margin: 7px;

  .credit-title {
    text-align: center;
    font-weight: bold;

    i {
      margin-right: 6px;
    }
  }

  hr {
    margin: 10px 0;
    border: none;
    border-top: 2px solid $ohBlack;
  }

  .credit-amount {
    font-size: 24px;
  }

  .credit-payment {
    input {
      width: 100%;
    }

    h4 {
      margin: 8px 0;
    }

    a {
      margin: 12px 0;
    }

    .popup {

      h5 {
        font-weight: normal;
      }

      .content {
        i {
          margin-right: 6px;
        }

        h4 {
          text-align: center;
        }
      }
    }
  }

  .credit-history-list {
    list-style: none;

    max-height: 50vh;

    overflow-y: auto;

    .credit-history-title {
      padding: 3px 0;
    }
    .credit-history-element {
      border-top: 1px solid $ohBlackOp;
      padding: 3px 0;
    }
  }

  .filleul-content {
    input {
      margin: 6px 0;
    }
  }
}

.month-margin{
  a {
    margin-bottom: 3px;
  }
}

.credit-no {
  text-align: center;
  font-style: italic;
}

@media (max-width: 800px) {

}
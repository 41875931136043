$navWidth: 250px;
$navWidthMobile: 50px;

.nav-panel {
  position: fixed;
  background: $ohBlackClicked;
  color: white;

  height: 100%;
  width: $navWidth;

  .separator {
    margin-top: 25px;
  }

  li {
    padding: 6px;
    width: $navWidth;

    &.logo {
      z-index: 9;

      height: 48px;
      margin-bottom: 10px;
      background: $ohBlackLight;
    }

    &.route {
      z-index: 7;

      a {
        color: $ohGrey;
        display: flex;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        padding: 4px 0;
        transition-duration : 0.3s;

        &:hover {
          background: $ohBlack;
        }

        i {
          font-size: 18px;
          text-align: center;
          margin-left: 6px;
          margin-right: 6px;
          padding-top: 1px;
          width: 25px;
          height: 22px;
        }

        span {
          text-align: center;
        }
      }

      .clicked {
        background: $ohBlack;
      }
    }

    &.name {
      position: fixed;
      bottom: 0;
      width: $navWidth;
      background: $ohBlackLight;
      color: $ohGrey;
      text-align: center;
      font-size: 12px;
    }
  }
}

.home-section {
  margin-left: $navWidth;
  padding: 16px;
  color: white;
}

@media (max-width: 800px) {
  .nav-panel {
    width: $navWidthMobile;

    li {

      &.logo {

        width: $navWidthMobile;

        span {
          display: none;
        }
      }

      &.route {

        width: $navWidthMobile;

        span {
          display: none;
        }
      }

      &.name {
        display: none;
      }
    }
  }

  .home-section {
    margin-left: $navWidthMobile;
  }
}

.panel-title {
  .page-desc {
    color: $ohGrey;
    font-size: 13px;
  }

  hr {
    margin: 12px 0;
    border: none;
    border-top: 2px solid $ohBlackLight;
  }
}
.cg {
  h2 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 24px;
    text-align: justify;
    text-justify: auto;
  }

  a {
    color: white;
    font-weight: bold;
  }
}
.bdd-panel {
  .title {
    padding-bottom: 10px;
  }

  .bdd-case {
    background: $ohBlackLight;
    padding: 10px;
    border-radius: 8px;
    margin: 7px;

    .brand {
      font-weight: bold;
      text-align: center;

      i {
        margin-right: 6px;
      }
    }

    hr {
      margin: 10px 0;
      border: none;
      border-top: 2px solid $ohBlack;
    }

    .bdd-create {
      .input-pre {
        margin-bottom: 10px;
        text-align: center;
      }
    }

    .bdd-perm {
      .perm-add-case {
        margin-bottom: 10px;
        .perm-check {
          input {
            margin: 0.4rem;
          }
        }
      }

      .users-list {
        list-style: none;

        .users-element {
          padding: 3px 0;

          .content {
            a {
              display: block;
              cursor: pointer;
            }
          }
        }

        .btn-sur {
          margin: 0 5px;
        }
      }

      .no-perm {
        color: $ohRed;
      }

      .yes-perm {
        color: $ohGreen;
      }
    }

    .bdd-access {
      .bdd-info {
        margin-bottom: 10px;

        strong {
          font-style: italic;
        }
      }
    }

    .bdd-settings {
      input[type='text'] {
        margin-bottom: 10px;
      }

      .consent {
        margin: 10px 0;

        input[type='checkbox'] {
          margin-right: 6px;
        }
      }
    }

    .bdd-home {
      .info-card {
        margin-bottom: 10px;
      }
    }
  }
}
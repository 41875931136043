.not-found {
  min-height: 85vh;
  text-align: center;
  padding-top: 25vh;

  a {
    margin-top: 12px;
  }
}


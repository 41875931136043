.header {
  color: white;
  padding: 12px 56px;

  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 19;
}

.nav-header a {
  margin: 0 5px;
}

label, #toggle
{
  display: none;
}

.nav-center {
  text-align: center;
}

.main-menu ul{
  list-style-type: none;
}

.dropdown {
  span {
    i {
      margin-left: 8px;
    }
  }
}

.drop{
  display: none;
  position: absolute;
  z-index: 1000;
  margin-top: 8px;
  background-color: $ohBlackOp;
  border-radius: 8px;
  transition-duration : 0.3s;

  a {
    width: 100%;
    margin: 0;
    text-align: left;
    font-size: 14px;

    div {
      font-size: 12px;
      color: $ohGrey;
    }
  }
}
.main-menu > ul .dropdown:hover .drop{
  display: block;
  padding: 6px;
}
.drop li{
  float: none;
  width: 100%;
  text-align: left;
  padding: 3px;
}

.info-offer {
  padding: 10px;
  font-size: 14px;

  span {
    padding-top: 2px;
  }

  i {
    color: $ohGreen;
    margin-right: 4px;
  }
}

@media (max-width: 800px) {
  .nav-header {
    .clicked-bar {
      text-align: right;
    }
  }

  .header {
    padding: 12px;
  }

  .main-menu {
    display: none;
    flex-direction: column;
  }

  label {
    display: inline;
    width: 30px;
    margin: 0 auto;
    font-size: 32px;
    color: white;
    cursor: pointer;
  }

  #toggle:checked + .main-menu {
    display: flex;
  }

  .mobile-raw {
    flex: 1;
  }
}
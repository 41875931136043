.footer {
  background-color: $ohBlack;
  color: white;
  padding: 25px 56px;
  border-top: 5px solid $ohBlueMat;

  text-align: center;

  p {
    margin-bottom: 3px;
  }

  a {
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  .footer {
    padding: 25px 12px;
  }
}
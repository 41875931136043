.bg-oh-blue {
  color: white;
  background: $ohBlueDark;
}

.bg-oh-blue-mat {
  color: white;
  background: $ohBlueMat;
}

.bg-oh-dark {
  color: white;
  background: $ohBlack;
}

.bg-oh-dark-light {
  color: white;
  background: $ohBlackLight;
}

.bg-oh-dark-op {
  color: white;
  background: $ohBlackOp;
}
.home-panel-container {
  .title {
    padding-bottom: 10px;
  }

  .info-box {
    background: $ohBlackLight;
    padding: 10px;
    border-radius: 8px;
    margin: 7px;

    text-align: center;

    .brand {
      font-weight: bold;
    }

    .visitor {
      color: $ohBlue;
    }

    .pro {
      color: $ohRed;
    }

    .particular {
      color: $ohGreen;
    }
  }

  hr {
    margin: 10px 0;
    border: none;
    border-top: 2px solid $ohBlack;
  }
}
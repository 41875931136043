.not {
  position: fixed;
  z-index: 100;

  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;

  left: 25px;
  top: -150px;

  max-width: 350px;

  .not-title {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: bold;

    border-radius: 8px 8px 0 0;
    padding: 6px 12px;

    display: block;
  }

  .not-data {
    color: black;
    font-size: 14px;

    padding: 6px 12px;

    display: block;
  }
}

.not-right {
  right: 25px;
  left: auto;
}

.not-remove {
  top: -150px !important;

  Animation-name : not-unspawn !important;
  Animation-delay : 0s !important;
  Animation-duration : 1s !important;
  Animation-iteration-count : initial !important;
}

@keyframes not-unspawn {
  0% {
    top: 25px;
  }

  100% {
    top: -150px;
  }
}

.not-active {
  top: 25px;

  Animation-name : not-spawn ;
  Animation-delay : 0s ;
  Animation-duration : 1s ;
  Animation-iteration-count : initial ;
}

@keyframes not-spawn {
  0% {
    top: -150px;
  }

  100% {
    top: 25px;
  }
}

.not-yes {
  .not-title {
    background: $ohGreen;
  }
}

.not-no {
  .not-title {
    background: $ohRed;
  }
}
%btn-default {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition-duration : 0.3s;

  color: white;
  background-color: #0000ff;

  &:hover {
    color: $ohGrey;
    background-color: #3b3bff;
  }
}

.btn {
  @extend %btn-default;
  padding: 8px 18px;
}

.btn-xs {
  @extend %btn-default;
  padding: 4px 6px;
}

.btn-sur {
  @extend %btn-default;
  padding: 1px 4px;
  font-size: 14px;
}

.btn-block {
  display: block;
}

.btn-oh-blue {
  background-color: $ohBlueDark;

  &:hover {
    background-color: $ohBlueLight;
  }
}

.btn-oh-dark {
  background-color: $ohBlack;

  &:hover {
    background-color: $ohBlackLight;
  }
}

.btn-oh-dark-two {
  background-color: $ohBlack;

  &:hover {
    background-color: $ohBlackSmallLight;
  }
}

.btn-oh-black {
  background-color: $ohBlackClicked;
  color: $ohGrey;

  &:hover {
    background-color: $ohBlackClickedHover;
  }
}

.btn-oh-dark-trans {
  background-color: transparent;

  &:hover {
    background-color: $ohBlackLight;
  }
}

.btn-oh-dark-clicked {
  background-color: $ohBlackClicked;

  &:hover {
    background-color: $ohBlackClicked;
  }
}
.btn-oh-red {
  background-color: $ohRed;
  color: white;

  &:hover {
    background-color: $ohRedLight;
    color: white;
  }
}

.btn-oh-green {
  background-color: $ohGreen;
  color: white;

  &:hover {
    background-color: $ohGreenLight;
    color: white;
  }
}


.btn-border-white {
  border: 2px solid white;
}
.PaymentMenu {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  z-index: 100;
  background-color: black;

  .stripe-button-el {
    display: none;
  }
}
.card-product {
  margin: 12px 6px;
  padding: 25px;
  border-radius: .75rem;
  border: 3px solid $ohBlack;

  background-color: $ohBlack;

  .product-general {
    text-align: center;

    .title {
      i {
        color: $ohYellow;
        padding-right: 6px;
      }
    }

    .desc {
      padding-top: 6px;
      text-align: left;
      color: $ohGrey;
      font-weight: lighter;
    }

    .price {
      padding: 24px 0;

      .unity {
        margin-left: 6px;
        font-size: 16px;
        font-weight: normal;
      }

      .text-background {
        font-size: 42px;
      }
    }

    .btn {
      display: block;
    }
  }

  .product-information {
    .product-list {
      p {
        margin: 6px 0;
        i {
          margin-right: 8px;
          @extend .text-background;
        }
      }

      a {
        color: white;
        font-weight: bold;
      }
    }
  }

  hr {
    margin: 24px 0;
    border: none;
    border-top: 2px solid $ohBlackLight !important;
  }

  transition-duration: 0.3s;

  &:hover {
    background-color: $ohBlackLight;

    hr {
      border-top: 2px solid $ohBlack !important;
    }
  }

  &:hover.color1 {
    border-color: $color2;
  }

  &:hover.color2 {
    border-color: $color3;
  }

  &:hover.color3 {
    border-color: $color1;
  }
}
.profile-container {

  .info-box {
    background: $ohBlackLight;
    padding: 10px;
    border-radius: 8px;
    margin: 7px;

    .brand {
      font-weight: bold;
      text-align: center;
    }

    i {
      margin-right: 6px;
    }

    .profile-title {
      font-weight: bold;
    }

    .profile-info {
      padding: 3px 6px;
    }

    .italic {
      font-style: italic;
    }

    .table {
      width: 100%;
    }

    .edit-card {
      .edit-element {
        padding: 6px;

        input {
          width: 100%;
          margin-top: 3px;
        }

        a {
          margin-top: 6px;
          display: block;
        }
      }

      .edit-valid {
        a {
          margin-top: 12px;
        }
      }
    }
  }

  hr {
    margin: 10px 0;
    border: none;
    border-top: 2px solid $ohBlack;
  }
}
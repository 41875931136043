.panel-instance {

  .mini-title {
    font-weight: normal;

    p {
      margin-bottom: 6px;
    }
  }

  .instance-loading {
    i {
      margin-left: 6px;
    }
  }

  .instance-list {
    list-style: none;

    .instance-element {
      background: $ohBlackLight;
      padding: 10px;
      margin: 15px 0;
      transition-duration : 0.3s;
      border-radius: 8px;

      .instance-description {
        font-style: italic;
      }

      .instance-id-text {
        color: $ohGrey;
      }

      .instance-btn {
        a {
          margin-top: 10px;
          margin-left: 10px;
        }
      }

      .status-bar {
        position: absolute;
        background-color: rgba(16,185,129);
      }

      .title {
        font-weight: bold;
      }

      .green {
        color: $ohGreen;
        margin-right: 4px;
      }

      .red {
        color: $ohRed;
        margin-right: 4px;
      }

      .yellow {
        color: $ohYellow;
        margin-right: 4px;
      }
    }

    .instance-element:hover {
      background: $ohBlackSombre;
    }

    a {
      color: white;
    }
  }

  .content {
    a {
      margin: 6px;
    }

    i {
      margin-right: 6px;
    }
  }
}

@media (max-width: 600px) {
  .panel-instance {
    .instance-list {
      .instance-element {
        .instance-id {
          display: none;
        }
      }
    }
  }
}
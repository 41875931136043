%input-default {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;

  &::selection {
    background: $ohBlueLight;
    color: white;
  }

  &:invalid {
    color: $ohRed;
  }
}

.input {
  @extend %input-default;
  padding: 8px 18px;
}

.input-xs {
  @extend %input-default;
  padding: 5px 10px;
}

.input-oh-black {
  color: white;
  background-color: $ohBlackClicked;
}

.input-block {
  display: block;
  width: 100%;
}


.input-pre {
  %input-default:nth-child(2n+1) {
    text-align: right;
    padding-right: 0;
    color: #989898;

    border-radius: 8px 0 0 8px;
  }

  %input-default:nth-child(2n) {
    text-align: left;
    padding-left: 0;

    border-radius: 0 8px 8px 0;
  }

  div {
    background-color: white;
  }
}
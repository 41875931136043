.header-title {
  height: 350px;
  position: relative;

  background-image: url("../../assets/img/home-svg.svg");
  background-repeat: no-repeat;
  background-position: right top 50%;
  background-size: 50%;

  Animation-name : move-svg ;
  Animation-delay : 0s ;
  Animation-duration : 120s ;
  Animation-iteration-count : initial;

  .centered-text {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 5%;
  }

  .big-title {
    letter-spacing: -.025em;
    font-size: 2.5rem;
    font-weight: bolder;
  }

  .min-title {
    color: rgba(149, 164, 185, 1);
    text-align: justify;
    text-justify: auto;
    font-weight: lighter;
    max-width: 50vw;
    font-size: 1.1rem;
  }

  .img-fade {
    position: absolute;
    right: 100%;
    bottom: 100%;
    margin-bottom: -14rem;
    margin-right: -18rem;

    height: auto;
    overflow: hidden;

    Animation-name : move-img ;
    Animation-delay : 0s ;
    Animation-duration : 10s ;
    Animation-iteration-count : infinite ;
  }

  @keyframes move-img {
    0% {
      margin-bottom: -14rem;
      margin-right: -18rem;
    }
    50% {
      margin-bottom: -18rem;
      margin-right: -20rem;
    }
    100% {
      margin-bottom: -14rem;
      margin-right: -18rem;
    }
  }
  @keyframes move-svg {
    0% {
      background-position: right top 0;
    }
    33% {
      background-position: right top 100%;
    }
    66% {
      background-position: right top 0;
    }
    100% {
      background-position: right top 50%;
    }
  }
}

.home-title {
  text-align: center;
  font-weight: bolder;
  display: block;
}

.panel-title-block {
  display: block;
}

.card {
  padding-top: 25px;
}

.fa-big {
  font-size: 24px;
}

.home-card-container {
  padding: 25px 5%;

  .home-case {
    margin: 6px;
    padding: 25px;
    min-height: 50px;
    border-radius: .75rem;
    border: 3px solid $ohBlack;

    background-color: $ohBlack;

    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    .home-case--header {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }

    h6 {
      margin-left: auto;

      padding-top: 10px;
      color: white;
      font-weight: lighter;
      font-size: 14px;
    }

    .mini-desc {
      margin-right: 3px;
    }

    span {
      font-weight: bolder;
      vertical-align: middle;
      font-size: 19px;
    }

    h5 {
      padding-top: 10px;
      color: $ohGrey;
      font-size: 16px;
      text-align: justify;
      text-justify: inter-word;
      font-weight: normal;
    }

    transition-duration: 0.3s;

    &:hover {
      background-color: $ohBlackLight;
    }

    &:hover.color1 {
      border-color: $color2;
    }

    &:hover.color2 {
      border-color: $color3;
    }

    &:hover.color3 {
      border-color: $color1;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.card-info {
  margin: 12px 6px;
  padding: 25px;
  border-radius: .75rem;
  border: 3px solid $ohBlack;

  background-color: $ohBlack;

  img {
    width: 80%;
    height: auto;
    max-width: 300px;
  }

  .text-area {
    .mini-title {
      text-transform: uppercase;
      font-size: 14px;
      color: $ohGrey;
    }

    .title {
      margin: 24px 6px;
      display: block;
    }

    .child {
      text-align: justify;
      text-justify: inter-word;
    }
  }

  .off {
    display: none;
  }

  transition-duration: 0.3s;

  &:hover {
    background-color: $ohBlackLight;
  }

  &:hover.color1 {
    border-color: $color2;
  }

  &:hover.color2 {
    border-color: $color3;
  }

  &:hover.color3 {
    border-color: $color1;
  }
}

@media (max-width: 800px) {
  .header-title {
    height: 360px;
    padding-top: 180px;

    .min-title {
      max-width: 100%;
    }
  }

  .card-info {
    .mobile-off {
      display: none !important;
    }

    .mobile-on {
      display: block !important;
      text-align: center;
    }
  }
}

@media (max-width: 630px) {
  .header-title {
    height: 370px;

    .big-title {
      font-size: 2.25rem;
    }
    .min-title {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 500px) {
  .header-title {
    height: 400px;
    padding-left: 12px;
    padding-right: 12px;

    .big-title {
      font-size: 2.25rem;
    }
    .min-title {
      font-size: 1.2rem;
    }
  }

  .home-card-container {
    padding: 25px 6px;
  }
}

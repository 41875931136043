@font-face {
  font-family: "Nunito";
  src: url("../assets/font/Nunito-VariableFont.ttf");
}

* {
  font-family: "Nunito", serif;
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  background: $ohBlack;
}

a {
  text-decoration: none;
}

.mini-logo-title {
  vertical-align: middle;
  margin-left: 3px;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.mini-logo-logo {
  height: 36px;
  width: 36px;
}

.text-right-align {
  text-align: right;
}

.text-left-align {
  text-align: left;
}

.text-center-align {
  text-align: center;
}

.text-background {
  background: rgb(199,210,254);
  background: linear-gradient(145deg, $color1 0%, $color2 10%, $color3 20%, $color1 30%, $color2 40%, $color3 50%, $color1 60%, $color2 70%, $color3 80%, $color1 90%, $color2 100%) fixed;
  font-weight: bolder;
  display: inline-block;

  background-clip: text;
  color: transparent;
}

img {
  vertical-align: middle;
}

.waiting-info {
  display: none;
}

.forgot-password {
  cursor: pointer;
  color: white;

  &:hover {
      text-decoration: underline;
  }
}

.green {
  color: $ohGreen;
}

.red {
  color: $ohRed;
}

.yellow {
  color: $ohYellow;
}

.waiting {
  :not(.waiting-info,.waiting-icon) {
    filter: blur(4px);
    pointer-events: none;
    cursor: default;
    user-select: none;
  }

  .waiting-info {
    color: white;
    position: absolute;
    display: block !important;
    z-index: 1000;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
}
.first-container {
  padding: 42px;
  height: 100vh;

  background-image: url("../../assets/img/home-svg.svg");
  background-repeat: no-repeat;
  background-position: left 10px top 40%;
  background-size: 95%;

  .login-separator {
    height: 85%;
  }

  .mini-login-text {
    color: $ohGrey;
  }

  .login-not-margin {
    margin: 0;
  }

  .cg-text {
    font-size: 13px;
  }

  .no-lm {
    margin-top: 6px;
  }

  .return-accueil i {
    margin-right: 4px;
  }
}

.login-container {
  margin: 42px;

  .remember span {
    vertical-align: middle;
    font-weight: bold;
  }

  .title {
    padding-bottom: 10px;

    p {
      color: $ohGrey;
      margin-top: 6px;
    }
  }

  .input-container {
    padding: 10px 0;

    input {
      width: 100%;
      margin: 10px 0;
    }

    .small-space {
      margin-top: 0;
    }
  }

  .end {
    padding-top: 10px;

    .remember {
      padding-bottom: 10px;
       input {
         margin-right: 6px;
       }
    }

    .btn {
      width: 100%;
    }
  }

  .no-account {
    padding-top: 30px;
    text-align: center;

    h4 {
      padding-bottom: 6px;
    }
  }

  strong {
    color: $ohRed;
  }
}

@media (max-width: 800px) {
  .login-container {
    margin: 12px;
  }

  .first-container {
    padding: 12px;
    height: 100%;

    .login-separator {
      height: 10px;
    }
  }

  .no-lm {
    display: none;
  }
}

@media (max-width: 400px) {
  .login-container {
    .title {
      h2 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
%sur {
  padding: 1px 4px;
  border-radius: 8px;
  color: white;
  font-size: 14px;
}

.sur-good {
  @extend %sur;
  background-color: $ohGreen;
}

.sur-cyan {
  @extend %sur;
  background-color: $ohBlue;
}

.sur-warn {
  @extend %sur;
  background-color: $ohOrange;
}

.sur-error {
  @extend %sur;
  background-color: $ohRed;
}